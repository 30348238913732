import "./style.less";

window.onscroll = () => {
    let top = window.pageYOffset;
    let header = document.querySelector('.header');
    let offset = header.offsetHeight;
    if (top > offset) {
        header.classList.add('header-fix')
    } else {
        header.classList.remove('header-fix')
    }
}

window.openMenu = () => {
    let block = document.querySelector('.menu-block');
    if (block.classList.contains('show')){
        block.classList.remove('show')
    } else {
        block.classList.add('show')
    }
}

new WOW().init();